<template>
  <base-section id="k-d-a-home-header" space="0">
    <base-img :src="require('@/assets/bg/bg-home-header.png')">
      <v-container fluid class="fill-height pa-0">
        <v-row :style="m_objContainer" no-gutters>
          <v-col cols="auto">
            <base-img
              :src="require('@/assets/logos/logo-kedatech-white-big.png')"
              position="left"
              contain
              v-bind="m_objImageSizeAttrs"
            ></base-img>

            <p
              :class="`${
                g_bLowerBr ? 'kda-ts-20pt mt-2' : 'kda-ts-36pt mt-6'
              } nunito ln-160 white--text`"
              style="max-width: 1038px"
            >
              {{ m_strContent }}
            </p>

            <v-btn
              color="main-r-400"
              :class="`text-capitalize white--text ${g_bLowerBr ? 'mt-4' : 'mt-10'}`"
              :x-large="!g_bLowerBr"
              @click="navToConsultations"
            >
              {{ m_strButton }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAHomeHeader',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_strContent() {
      return this.$vuetify.lang.t('$vuetify.kda.common.book-banner.desc');
    },
    m_strButton() {
      return this.$vuetify.lang.t('$vuetify.kda.common.book-banner.btn');
    },
    m_objContainer() {
      let paddingTop = '212px';
      let paddingBottom = '64px';
      let paddingX = '64px';

      if (this.g_bLowerBr) {
        paddingTop = '124px';
        paddingBottom = paddingTop;
        paddingX = '32px';
      }

      return { padding: `${paddingTop} ${paddingX} ${paddingBottom}` };
    },
    m_objImageSizeAttrs() {
      const result = { maxHeight: 254 };

      if (this.g_bLowerBr) {
        result.maxWidth = 250;
      }

      return result;
    }
  },

  methods: {
    navToConsultations() {
      window.gtag('event', 'click', {
        'event_label': 'Book A Chat'
      });

      this.$router.push('/consultations');
    }
  }
};
</script>
