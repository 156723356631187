const arrBusinessContacts = [
  {
    strIcon: 'icon-location.png',
    strIconWhite: 'icon-location-white.png',
    strTitle: 'Address',
    arrTexts: [
      'Komplek Green Ville, Jalan Ratu Kemuning',
      'Blok BL No. 1, RT.8/RW.14',
      'Daerah Khusus Ibukota Jakarta'
    ]
  },
  {
    strIcon: 'icon-phone.png',
    strIconWhite: 'icon-phone-white.png',
    strTitle: 'Phone',
    arrTexts: ['+(62-81) 180-878-87 ']
  },
  {
    strIcon: 'icon-mail.png',
    strIconWhite: 'icon-mail-white.png',
    strTitle: 'Email',
    arrTexts: ['cs@keda-tech.com']
  }
];

const strBusinessDescriptionShort =
  'KeDa Tech is a software house based in Jakarta, Indonesia. We cater any client needs including the development of software, apps, or website.';
const strBusinessDescriptionLong =
  'KeDa Tech is a software house based in Jakarta, Indonesia. We cater any client needs including the development of software, apps, or website. Should you have any questions regarding our business, feel free to reach us by your preferred contact method.';
const htmlBusinessDescriptionShort =
  '<b>KeDa Tech</b> is a software house based in <b>Jakarta, Indonesia</b>.<br> We cater any client needs including the development of software, apps, or website.';
const htmlBusinessDescriptionLong =
  '<b>KeDa Tech</b> is a software house based in <b>Jakarta, Indonesia</b>.<br> We cater any client needs including the development of software, apps, or website.<br><br> Should you have any questions regarding our business, feel free to reach us by your preferred contact method.';

const htmlAboutDescription =
  'Berawal dari mimpi menjadi software house berstandar internasional, KeDA Tech hadir di tahun 2018. Selama pengembangannya, KeDA Tech percaya bahwa transformasi digital akan membawa banyak perubahan dalam segala aspek kehidupan. <br><br>Terlebih lagi dalam bidang bisnis perusahaan, komunitas sampai masyarakat.';
const htmlJourney1 =
  'Berawal dari satu tim yang berisi 2 programmer dan seorang designer, KeDA Tech berdiri dan mampu menyelesaikan project pertamanya yaitu, <b>Container Loader Project</b>.';
const htmlJourney2 =
  'Selang setahun KeDA Tech berdiri, kerja sama yang baru pun di mulai dengan <b>AXA</b>. Dalam project ini kami membuat <b>VR Simulator</b>, yang berhasil diselesaikan oleh 3 programmer dan seorang designer dalam waktu 2 minggu.';
const htmlJourney3 =
  'Bekerja sama dengan salah satu perusahaan properti terbesar di Jakarta, KeDA Tech menyelesaikan aplikasi chatbot untuk <b>SQM Property</b>.';
const htmlJourney4 =
  'Dengan berbagai project yang sudah kami lakukan dengan perusahaan pengembang lainnya seperti <b>Shinta VR, Wallex, Luceria</b> dan <b>PT Citra Kreasi Makmur</b>, Kami juga ingin menjadi bagian dari kemajuan bisnis digital Anda. Dengan lebih dari 10 programmer saat ini, KeDA Tech terus berkomitmen untuk memberikan pelayanan yang terbaik bagi Anda';
const htmlStoryBegin =
  'Setelah menempuh pendidikan master di California-Amerika, tahun 2017 Kenneth Nursalim kembali ke Indonesia. Beberapa tahun di Amerika membuatnya menyadari perkembangan sistem informasi dan teknologi di Indonesia tertinggal cukup jauh. <br><br>Karena itu, di tahun 2018, Kenneth Nursalim mendirikan KeDA Tech dengan visi menjadi software house yang memiliki standar internasional. ';
export default {
  name: 'BusinessDetails',

  data: () => ({
    g_arrBusinessContacts: arrBusinessContacts,
    g_strBusinessDescriptionShort: strBusinessDescriptionShort,
    g_strBusinessDescriptionLong: strBusinessDescriptionLong,
    g_htmlBusinessDescriptionShort: htmlBusinessDescriptionShort,
    g_htmlBusinessDescriptionLong: htmlBusinessDescriptionLong,
    g_htmlAboutDescription: htmlAboutDescription,
    g_htmlJourney1: htmlJourney1,
    g_htmlJourney2: htmlJourney2,
    g_htmlJourney3: htmlJourney3,
    g_htmlJourney4: htmlJourney4,
    g_htmlStoryBegin: htmlStoryBegin
  })
};
